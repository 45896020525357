import React, { useEffect, useState } from "react";
import "./profileView.css";

import axios from "axios";
import config from "../config";
import { Slider } from "./sections/Slider";
import { StrideProfile } from "./sections/StrideProfile";
import { DownloadAppCard } from "./sections/DownloadAppCard";

const ProfileView = () => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    axios
      .get(
        `${
          config.BASE_URL
        }/digital-profile-view/${window.location.pathname.slice(1)}`
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          minWidth: "360px",
          maxWidth: "576px",
          padding: "20px 0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div
            style={{
              color: "#3279e7",
              width: "50%",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Gordita",
            }}
          >
            {data?.name}
          </div>
          <div
            style={{
              width: "50%",
            }}
          >
            {data?.looking_for?.map((item: any, index: any) => (
              <div
                key={index}
                style={{
                  color: "#3279e7",
                  fontSize: "14px",
                  padding: "5px 10px",
                  borderRadius: "20px",
                  fontFamily: "Gordita",
                  margin: "0 5px 5px 0",
                  display: "inline-block",
                  backgroundColor: "#cee0ff",
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            width: "80%",
            height: "1px",
            opacity: "0.4",
            margin: "20px 10%",
            backgroundColor: "#d9e0eb",
          }}
        ></div>
        <div style={{ padding: "0 20px" }}>
          <div
            style={{
              color: "#192a46",
              fontSize: "20px",
              fontWeight: "bold",
              fontFamily: "Gordita",
            }}
          >
            Interest Areas
          </div>
          <div style={{ width: "100%", marginTop: "15px" }}>
            {data?.interest_areas?.map((item: any, index: any) => (
              <div
                key={index}
                style={{
                  fontSize: "14px",
                  color: "#717883",
                  borderRadius: "20px",
                  padding: "10px 15px",
                  margin: "0 5px 5px 0",
                  fontFamily: "Gordita",
                  display: "inline-flex",
                  backgroundColor: "#f0f0f0",
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            height: "5px",
            width: "100%",
            opacity: "0.4",
            margin: "20px 0",
            backgroundColor: "#d9e0eb",
          }}
        ></div>

        {data?.strengths ? (
          <>
            <div style={{ padding: "0 20px" }}>
              <div
                style={{
                  color: "#192a46",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "Gordita",
                }}
              >
                Strong Areas
              </div>
              <ul>
                {data?.strengths?.map((item: any, index: any) => (
                  <li
                    key={index}
                    style={{ color: "#717883", fontFamily: "Gordita" }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div
              style={{
                height: "5px",
                width: "100%",
                opacity: "0.4",
                margin: "20px 0",
                backgroundColor: "#d9e0eb",
              }}
            ></div>
          </>
        ) : null}

        <div style={{ padding: "0 20px" }}>
          <div
            style={{
              color: "#192a46",
              fontSize: "20px",
              fontWeight: "bold",
              fontFamily: "Gordita",
            }}
          >
            Stride Profile
          </div>
          {data?.digital_profile_summary
            ?.sort((a: any, b: any) =>
              a?.value?.length < b?.value?.length ? -1 : 1
            )
            ?.map((logData: any, index: any) => (
              <StrideProfile logData={logData} index={index} />
            ))}
        </div>

        <div
          style={{
            height: "5px",
            width: "100%",
            opacity: "0.4",
            margin: "20px 0",
            backgroundColor: "#d9e0eb",
          }}
        ></div>

        {data?.job_family_recommendation ? (
          <div style={{ margin: "0 20px" }}>
            <div
              style={{
                color: "#192a46",
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "Gordita",
              }}
            >
              Best Fit Job Roles
            </div>
            <div className="main-content">
              {data?.job_family_recommendation?.result?.map(
                (item: any, index: any) => (
                  <div
                    className="content-header"
                    key={index}
                    style={{
                      marginLeft: index === 0 ? "" : "15px",
                    }}
                  >
                    <div className="content-item">{item.name}</div>
                    <div className="content-overview">
                      <p className="contenr-item-overviwe"> {item.overview}</p>
                    </div>
                    <div className="content-description">
                      {item.tags?.map((t: any, i: any) => (
                        <div className="content-text" key={i}>
                          {t}
                        </div>
                      ))}
                    </div>
                    <div className="content-types"></div>
                    <div className="stride-match-title">
                      Stride Match{" "}
                      <span
                        className="tooltip"
                        data-tooltip={
                          data?.job_family_recommendation
                            .stride_match_definition
                        }
                        style={{ color: "#1c6ae4", cursor: "pointer" }}
                        // onClick={() => window.open("", "_blank")}
                      >
                        &#9432;
                      </span>
                    </div>
                    <div className="stride-match-score">
                      <Slider score={item.stride_match_score} />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : null}

        {data?.potential_strengths ? (
          <>
            <div
              style={{
                height: "5px",
                width: "100%",
                opacity: "0.4",
                margin: "20px 0",
                backgroundColor: "#d9e0eb",
              }}
            ></div>

            <div style={{ padding: "0 20px" }}>
              <div
                style={{
                  color: "#192a46",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "Gordita",
                }}
              >
                Potential Area of Development
              </div>
              <ul>
                {data?.potential_strengths?.map((item: any, index: any) => (
                  <li
                    key={index}
                    style={{ color: "#717883", fontFamily: "Gordita" }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : null}

        <DownloadAppCard />
      </div>
    </div>
  );
};

export default ProfileView;
