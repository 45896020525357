import "./strideProfile.css";
export const StrideProfile = ({ logData, index }: any) => {
  return (
    <div
      className="profile-main"
      style={{
        backgroundColor: logData.background,
        width: index % 3 === 2 ? "100%" : "45%",
        marginRight: index % 3 === 0 ? "10%" : "0",
      }}
    >
      <div className="profile-image-container" key={index}>
        <img
          className="image-container"
          alt={logData.icon}
          src={logData.icon}
        />
        <div className="lable-section">
          <div className="logdata-label">{logData.label}</div>
          <div className="logdata-value">{logData.value}</div>
        </div>
      </div>
    </div>
  );
};
