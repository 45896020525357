import { appStoreIcon, mobileMockupImg, playStoreIcon } from "../../assets";

export const DownloadAppCard = () => {
  return (
    <div
      style={{
        padding: "20px",
        marginTop: "10px",
        backgroundColor: "#e5e5e5",
      }}
    >
      <div
        style={{
          display: "flex",
          borderRadius: "20px",
          backgroundColor: "#193259",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "65%",
            padding: "20px",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              color: "#fff",
              fontSize: "25px",
              fontWeight: "bold",
              marginBottom: "10px",
              fontFamily: "Gordita",
            }}
          >
            Wants to create your own profile
          </div>
          <div
            style={{
              color: "#fff",
              opacity: "0.5",
              marginBottom: "10px",
              fontFamily: "Gordita",
            }}
          >
            Download Our 'Strideahead' App
          </div>
          <div>
	  <a href="https://pages.strideahead.in/download-app" target="_blank" rel="noreferrer">
            <img
              src={playStoreIcon}
              style={{ width: "100px", marginRight: "10px" }}
            />
	  </a>
	  <a href="https://pages.strideahead.in/download-app" target="_blank" rel="noreferrer">
            <img src={appStoreIcon} style={{ width: "100px" }} />
	  </a>
          </div>
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
          }}
        >
          <img
            src={mobileMockupImg}
            style={{
              width: "100%",
              marginTop: "5px",
            }}
          />
        </div>
        <div style={{ width: "5%" }}></div>
      </div>
    </div>
  );
};
