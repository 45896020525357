const config = {
  fontBold: "Montserrat-Bold",
  fontLight: "Montserrat-Light",
  fontMedium: "Montserrat-Medium",
  fontRegular: "Montserrat-Regular",
  fontSemiBold: "Montserrat-SemiBold",
  contactMobileNumber: "+919953089067",
  BASE_CDN: "https://dxbi3yg46ezk5.cloudfront.net",
  BASE_URL: "https://auth.strideahead.in",
  TEST_API_URL: "https://tests-backend.strideahead.in",
  APP_BACKEND_URL: "https://app-backend.strideahead.in",
  googleAuthClientId:
    "58458695792-f0gvitetg9n1r95vdhbbbkn093qf4998.apps.googleusercontent.com",
};

export default config;
