import React from "react";

export const Slider = ({ score }: any) => (
  <div style={{ marginTop: "70px" }}>
    <div
      style={{
        width: "100%",
        height: "10px",
        borderRadius: "10px",
        backgroundColor: "rgba(25, 42, 70, 0.1)",
      }}
    >
      <div
        style={{
          height: "10px",
          width: `${score}%`,
          position: "relative",
          borderRadius: "10px",
          backgroundColor: "#192a46",
        }}
      >
        <div
          style={{
            left: "90%",
            top: "-55px",
            padding: "3px",
            borderRadius: "50%",
            position: "absolute",
            border: "2px solid #192a46",
          }}
        >
          <div
            style={{
              color: "#fff",
              width: "36px",
              height: "36px",
              display: "flex",
              fontSize: "8px",
              borderRadius: "50%",
              alignItems: "center",
              backgroundColor: "#192146",
              justifyContent: "center",
            }}
          >
            {score}%
          </div>
        </div>
        <div
          style={{
            top: "-5px",
            left: "95%",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            position: "absolute",
            backgroundColor: "#192a46",
          }}
        ></div>
        <div
          style={{
            width: 0,
            height: 0,
            left: "97%",
            top: "-15px",
            position: "absolute",
            borderTop: "6px solid #192a46",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
          }}
        ></div>
      </div>
    </div>
  </div>
);
